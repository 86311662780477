import { useState } from 'react';
import { ReactEditor, useSlateStatic, useSelected, useFocused } from 'slate-react';
import { Transforms } from 'slate'
import Plot from 'react-plotly.js';
import { css } from '@emotion/css'
import { Modal, ListGroup, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import _ from 'lodash';

import { use } from '../../redux/Factory';
import { Button, Icon } from './Components'
import { DeleteConfirmButton, SmallSpinner } from '../shared/ReactToolbox';

const Element = (
  ({ attributes, children, element, slug }) => {
    const editor = useSlateStatic()
    const selected = useSelected()
    const focused = useFocused()
    const path = ReactEditor.findPath(editor, element)
    const readOnly = ReactEditor.isReadOnly(editor)
    const { chart, getChartsList, getChart } = use.charts({ slug });

    const { index = 0 } = element;
    return (
      <div {...attributes}>
        <div
          contentEditable={false}
          className={css`
            position: relative;
            text-align: center;
          `}
        >
          {chart 
            ? <div>
                <Plot
                  data={chart.figures && chart.figures[index] && chart.figures[index].data}
                  layout={chart.figures && chart.figures[index] && chart.figures[index].layout}
                  className={css`
                    display: inline;
                    position: absolute;
                  `}
                />
                {!readOnly &&
                  <div
                    className={css`
                      display: ${selected ? 'inline' : 'none'};
                      position: absolute;
                      top: 0.5em;
                      left: 0.5em;
                      background-color: white;
                    `}
                  >
                    <ButtonGroup>
                      <DeleteConfirmButton
                        onDelete={() => Transforms.removeNodes(editor, { at: path })}
                      />
                    
                      {chart.figures &&
                        <DropdownButton
                          id="dropdown"
                          title={chart.figures[index].layout?.title?.text || ''}
                          onClick={e => e.stopPropagation()}
                          size="sm"
                          variant="light"
                          disabled={chart.figures.length === 1}
                        >
                          {chart.figures.map(({ layout }, key) =>
                              <Dropdown.Item
                                href="#"
                                key={key}
                                onClick={(e) => Transforms.setNodes(editor, { index: key }, { at: path })}
                              >
                                {layout?.title?.text || 'Untitled'}
                              </Dropdown.Item>
                            )
                          }
                        </DropdownButton>
                      }
                    </ButtonGroup>
                  </div>
                }
              </div>
            : getChartsList.isLoading || getChart.isLoading // || _.isEqual(chartsList, {})
            ? <SmallSpinner />
            : <span>Chart not found: <i>{slug}:{index}</i></span>
          }
        </div>
        {children}
      </div>
    )
  }
);
export const ChartElement = (props) => <Element {...props} slug={props.element.slug} />

const insertChart = (editor, slug) => {
  const text = { text: '' }
  const chart = { type: 'plotly-chart', slug, index: 0, children: [text] }
  Transforms.insertNodes(editor, chart)
}

export const InsertChartButton = () => {
  const { chartsList } = use.charts();
  const [chartsModalActive, setChartsModalActive] = useState(false);
  const editor = useSlateStatic()
  return <>
    <Button
      onMouseDown={event => {
        event.preventDefault()
        setChartsModalActive(true);
      }}
    >
    <Icon>addchart</Icon>
    </Button>
    
    <Modal
      show={chartsModalActive}
      onHide={() => setChartsModalActive(false)}
      onClick={e => e.stopPropagation()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Insert chart</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ListGroup>
          {chartsList && Object.values(chartsList).map(({ name, slug, figures }, index) =>
            <ListGroup.Item
              key={index}
              onClick={() => {
                if (!figures) return;
                insertChart(editor, slug);
                setChartsModalActive(false);
              }}
              style={{ cursor: 'pointer' }}
              disabled={!figures}
            >
              {name}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>

      <Modal.Footer>
        {false && <SmallSpinner />}
        <Button
          variant="secondary"
          onClick={() => setChartsModalActive(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
};

export const withChart = editor => {
  const { isVoid } = editor
  editor.isVoid = element => (element.type === 'plotly-chart' ? true : isVoid(element))
  return editor
}