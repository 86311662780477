import { useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/keymap/sublime';
import 'codemirror/mode/python/python';
import { Container, Spinner, Tabs, Tab } from 'react-bootstrap';
import SplitPane from 'react-split-pane';
import Plot from 'react-plotly.js';

import './Chart.css';
import { SmallSpinner, ErrorPage } from '../shared/ReactToolbox';
import { use } from '../../redux/Factory';
import { DashboardTitle } from '../dashboard/Dashboard';

const Chart = ({ slug }) => {
  const { getChart, getChartsList, updateChart, chart } = use.charts({ slug });
  useEffect(() => {
    if (chart === null) getChart(slug);
  }, [slug])

  const [scriptModified, setScriptModified] = useState(null);
  const [saved, setSaved] = useState(true);
  const [index, setChartIndex] = useState(0);

  const chartIndex = chart && chart.figures && index < chart.figures.length ? index : 0;

  if (getChart.error) {
    return <ErrorPage>Chart not found</ErrorPage>

  } else if(getChartsList.isLoading || getChart.isLoading || chart === null) {
    return <SmallSpinner />

  } else if (chart && scriptModified === null) {
    setScriptModified(chart.script);
  }

  const handleKeyDown = evt => {
    const charCode = String.fromCharCode(evt.which).toLowerCase();
    if ((evt.ctrlKey || evt.metaKey) && charCode === 's') {
      evt.preventDefault();
      updateChart(
        { ...chart, script: scriptModified },
        {
          callback: ({ error }) => {
            if (chart.script === scriptModified) setSaved(true);
          },
        }
      );
    }
  }
  return (<>
    <DashboardTitle>
      {updateChart.isLoading &&
        <Spinner
          animation="border"
          role="status"
          size="sm"
          className="float-end"
          style={{ marginLeft: '10px' }}
        />}
      {chart && chart.name}
    </DashboardTitle>
    <Container fluid style={{ height: '100%', position: 'fixed' }}>
      <SplitPane split="vertical" defaultSize="50%" style={{ height: '100%' }}>
        <div onKeyDown={handleKeyDown}>
          <CodeMirror
            value={scriptModified}
            options={{
              mode: 'python',
              theme: 'material',
              indentUnit: 4,
              indentWithTabs: false,
              lineNumbers: true,
              keyMap: 'sublime',
              // extraKeys: {
              //   Tab: function(cm) {
              //     var spaces = Array(cm.getOption("indentUnit") + 1).join(" ");
              //     cm.replaceSelection(spaces);
              //   }
              // });
            }}
            onBeforeChange={(editor, data, value) => {
              setScriptModified(value);
              setSaved(false)
            }}
            onChange={(editor, data, value) => {
            }}
          />
        </div>
        <div className="row-container">
          {chart.figures && chart.error === null && <div className='plotly-chart'>
            {chart.figures.length > 1 &&
              <Tabs
                activeKey={`${chartIndex}`}
                onSelect={(index) => setChartIndex(parseInt(index))}
                className="mb-3"
              >
                {chart.figures.map(({ layout }, index) =>
                  <Tab
                    eventKey={`${index}`}
                    title={layout?.title?.text || 'Untitled'}
                    key={index}
                  />
                )}
              </Tabs>
            }
            {chart.figures[chartIndex] &&
              <Plot
                data={chart.figures[chartIndex].data}
                layout={chart.figures[chartIndex].layout}
              />
            }
          </div>}
          <div>
            {chart.error &&
              <div
                style={{
                  paddingBottom: '16px',
                  // background: 'var(--bs-body-bg)'
                }}
              >
                <CodeMirror
                  value={chart.error}
                  options={{
                    theme: 'material',
                    lineNumbers: true,
                  }}
                />
              </div>
            }
            <CodeMirror
              value={chart.output || ''}
              options={{
                theme: 'material',
                lineNumbers: true,
              }}
            />
          </div>       
        </div>
        
      </SplitPane>
    </Container>
  </>);
}

export default Chart;
